<template>
  <validation-observer ref="simpleRules">
    <!-- <this-header /> -->
    <div class="anan-set-nav-conttainer mt-150">
      <router-link :to="{name : 'new-pages'}">
        <b-button
          class="btn btn-gradient-primary mb-1 mr-50 mr-50"
        >
          <i class="fas fa-arrow-left mr-25" /> {{ $t('key-126') }}
        </b-button>
      </router-link>
      <div class="row mb-2">
        <div class="col-md-4">
          <div
            class="anan-fyt-tab"
            :class="{ active: isActive === '0' }"
            @click="getData(status = 0,search_val), isActive = '0'"
          >
            <span class="mb-25"><i class="far fa-list-ol" /> {{ $t('all') }} </span>
          </div>
        </div>

        <div class="col-md-4">
          <div
            class="anan-fyt-tab"
            :class="{ active: isActive === '1' }"
            @click="getData(status = 1,search_val), isActive = '1'"
          >
            <span class="mb-25"><i class="fas fa-history" /> {{ $t('pendingReview') }} </span>
          </div>
        </div>

        <div class="col-md-4">
          <div
            class="anan-fyt-tab"
            :class="{ active: isActive === '2' }"
            @click="getData(status = 2,search_val), isActive = '2'"
          >
            <span class="mb-25"><i class="fas fa-ban" /> {{ $t('cancel') }} </span>
          </div>
        </div>
      </div>

      <div
        class="card"
      >
        <div class="section-title pt-2 pr-2 pl-2">
          <span class="font-weight-bolder">{{ $t('reportMissingGoods') }}</span>

          <div class="d-flex">
            <div class="income-order-search order-search w-100">
              <div class="anan-input search-input">
                <div class="anan-input__inner anan-input__inner--normal">
                  <input
                    v-model="search_val"
                    type="text"
                    :placeholder="$t('search')"
                    class="anan-input__input"
                    @keyup.enter="getData(status,search_val)"
                  >
                  <div class="anan-input__suffix">
                    <button
                      type="button"
                      class="anan-button anan-button--normal anan-button--primary"
                      @click="getData(status,search_val)"
                    >
                      <i class="fal fa-search" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="px-2">
          <router-link
            :to="{name: 'lost-package-add'}"
          >
            <button
              type="button"
              class="anan-button--xl-large anan-button anan-button--primary anan-button--normal"
            >
              <span><i class="fal fa-plus" /> {{ $t('addNewItem') }} </span>
            </button>
          </router-link>
        </div>
        <b-overlay
          :show="showOver"
          variant="transparent"
          opacity="0.99"
          blur="5px"
          rounded="sm"
        >
          <template #overlay>
            <div class="text-center">
              <p id="cancel-label">
                กรุณารอสักครู่...
              </p>
            </div>
          </template>
          <div class="pb-2 pr-2 pl-2">
            <b-table
              striped
              hover
              responsive
              :per-page="perPage"
              :items="items"
              :fields="fieldsYuan"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :sort-direction="sortDirection"
              :filter="filter"
              :filter-included-fields="filterOn"
              show-empty
            >
              <template #table-busy>
                <div class="text-center text-danger my-2">
                  <b-spinner
                    label="กำลังโหลด..."
                    variant="danger"
                  />
                </div>
              </template>

              <template #empty>
                <div class="text-center">
                  <img
                    src="@/assets/images/anan-img/empty/empty.png"
                    alt="empty"
                    width="180px"
                  >
                  <p>No Data</p>
                </div>
              </template>

              <!-- <template #cell(itemnumber_text)="data">
              {{ data.item.itemnumber_text }} -->
              <!-- <b-link :to="{name: 'admin-user-type-edit', params: {id: data.item.id}}">
                ORD-{{ items.length - (perPage * (currentPage - 1) + (data.index + 1)) + 1 }}
              </b-link> -->
              <!-- </template> -->
              <template #cell(detail)="data">
                <span
                  v-for="(item,index) in data.item.detail"
                  :key="index"
                >
                  {{ index+1 }}. {{ item.detail }} <br>
                </span>
              </template>
              <template #cell(created_at)="data">
                {{ time(data.item.created_at) }}
              </template>
              <template #cell(order_text)="data">
                <b-link
                  :to="{name : 'order-detail', params: {id: data.item.order_id} }"
                  class="mb-0"
                >
                  {{ data.item.order_text }}
                </b-link>
              </template>

              <template #cell(fileimg)="data">
                <span
                  v-for="imagePr in data.item.fileimg"
                  :key="imagePr"
                  class="preview-image-container mr-25 d-flex justify-content-center"
                >
                  <div class="preview-image-overlays p-0">
                    <img
                      :src="GetImg('lostpackage_file',imagePr)"
                      alt="asd"
                      class="rounded mr5"
                      style="max-width: 100%; height: auto;"
                      @click="showPicture(imagePr)"
                    >

                  </div>
                </span>
              </template>
              <template #cell(refund)="data">
                <span
                  v-for="(item,index) in data.item.detail"
                  :key="index"
                >
                  <span v-if="item.check_balance == 1"><i class="fas fa-history text-primary" /> </span>
                  <span v-if="item.check_balance == 2"><i class="fas fa-check text-success" /></span>
                  <span v-if="item.check_balance == 3"><i class="fas fa-ban text-danger" /></span>
                  {{ item.balance }} ¥ <br>
                </span>
              </template>
              <template #cell(amount)="data">
                {{ data.item.detail.reduce((total,item) => total + item.balance,0) }} ¥
              </template>
              <!-- <template #cell(index)="data">
              {{ perPage * (currentPage - 1) + (data.index + 1) }}
            </template>

            <template #cell(transactionDate)="data">
              {{ time(data.item.transactionDate) }}
            </template>

            <template #cell(amount)="data">
              {{ Commas(data.item.amount) }}฿
            </template>

            <template #cell(document)="data">
              <img
                :src="data.item.document"
                alt="img-document"
                class="border"
                height="40"
              >
            </template>

            <template #cell(status)="data">
              <span
                v-if="data.item.status === 'waiting'"
                class="badge badge-primary badge-pill"
              > รอตรวจสอบ </span>

              <span
                v-if="data.item.status === 'success'"
                class="badge badge-success badge-pill"
              > ดำเนินการสำเร็จ </span>
            </template> -->

              <template #cell(action)="data">
                <feather-icon
                  icon="Edit3Icon"
                  class="mr-1 text-primary cursor-pointer"
                  @click="EditType(data.item)"
                />
              </template>
            </b-table>

            <div class="demo-spacing-0 d-flex justify-content-end">
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                first-number
                last-number
                prev-class="prev-item"
                next-class="next-item"
                class="mb-0"
                @input="getData(status,search_val )"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </div>
        </b-overlay>
      </div>

      <b-modal
        id="modal-add-new"
        :title="$t('key-21')"
        :ok-title="$t('save')"
        :cancel-title="$t('cancel')"
        @ok.prevent="submit()"
      >
        <b-form-group>
          <validation-provider
            #default="{ errors }"
            name="Customer Code"
            rules="required"
          >
            <label for="customerCode">{{ $t('customerCode') }} : <span class="text-danger">*</span></label>
            <v-select
              v-model="username_cus"
              label="username"
              :options="cuslist"
              :reduce="username => username.username"
            />
            <span class="text-danger">{{ errors[0] }}</span>
          </validation-provider>
        </b-form-group>

        <b-form-group>
          <validation-provider
            #default="{ errors }"
            name="Type"
            rules="required"
          >
            <label for="type">{{ $t('type') }} : <span class="text-danger">*</span></label>
            <b-form-select
              v-model="type"
              :options="typeOp"
              :state="errors.length > 0 ? false:null"
            />
            <span class="text-danger">{{ errors[0] }}</span>
          </validation-provider>
        </b-form-group>

        <b-form-group>
          <label for="detail">{{ $t('details') }} :</label>
          <b-form-textarea
            id="detail"
            v-model="detail"
            :placeholder="$t('details')"
          />
        </b-form-group>

        <b-form-group>
          <validation-provider
            #default="{ errors }"
            name="Amount"
            rules="required"
          >
            <label for="amount">{{ $t('quantity') }} : <span class="text-danger">*</span></label>
            <b-form-input
              id="amount"
              v-model="amount"
              type="number"
              :state="errors.length > 0 ? false:null"
              :placeholder="$t('quantity')"
            />
            <span class="text-danger">{{ errors[0] }}</span>
          </validation-provider>
        </b-form-group>

        <b-form-group>
          <label for="prefix">Prefix :</label>
          <b-form-input
            id="prefix"
            v-model="prefix"
            type="text"
            placeholder="Prefix"
          />
        </b-form-group>

        <b-form-group>
          <label for="prefixNum">Prefix Number :</label>
          <b-form-input
            id="prefixNum"
            v-model="prefixnumber"
            type="number"
            placeholder="Prefix Number"
          />
        </b-form-group>
      </b-modal>
    </div>

    <vue-image-lightbox-carousel
      ref="lightbox"
      :show="showLightbox"
      :images="images"
      :show-caption="false"
      @close="closeBox()"
    />
  </validation-observer>
</template>

<script>
// eslint-disable-next-line import/no-extraneous-dependencies
import moment from 'moment-timezone'
import {
  BPagination, BLink, BTable, BSpinner, BFormSelect, BFormTextarea, BFormGroup, BFormInput, BOverlay,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import vSelect from 'vue-select'
import VueImageLightboxCarousel from 'vue-image-lightbox-carousel'
// import ThisHeader from '@/views/customer/component/ThisHeader.vue'
// import router from '@/router'

export default {
  components: {
    BPagination,
    BLink,
    BTable,
    BSpinner,
    BFormSelect,
    BFormTextarea,
    ValidationProvider,
    ValidationObserver,
    vSelect,
    BFormGroup,
    BFormInput,
    // ThisHeader,
    BOverlay,
    VueImageLightboxCarousel,
    // router,
  },
  data() {
    return {
      images: [],
      showLightbox: false,
      required,
      selected: null,
      id: null,
      amount: 0,
      detail: '',
      type: null,
      username_cus: '',
      prefix: '',
      prefixnumber: '',
      search: '',
      isActive: '0',
      selectedRadio: 'active',
      optionsRadio: [
        { text: 'All', value: 'all' },
        { text: 'Car', value: 'car' },
        { text: 'Ship', value: 'ship' },
      ],
      pageOptions: [5, 10, 15, 20],
      currentPage: 1,
      perPage: 7,
      totalRows: 1,

      // Options
      taobaoStatus: null,
      options: [
        { value: null, text: 'ทั้งหมด' },
        { value: 1, text: 'ยกเลิก' },
        { value: 2, text: 'รอตรวจสอบ' },
        { value: 3, text: 'ดำเนินการสำเร็จ' },
      ],

      typeOp: [
        { value: 1, text: 'เติมเงิน' },
        { value: 2, text: 'ถอนเงิน' },
        { value: 3, text: 'ชำระค่านำเข้า' },
        { value: 4, text: 'ค่าสินค้า' },
        { value: 5, text: 'ส่วนต่างค่าขนส่งในไทย' },
        { value: 6, text: 'ค่าสินค้าใน MALL' },
        { value: 7, text: 'ฝากจ่ายเถาเปา' },
        { value: 8, text: 'ค่า Commission' },
        { value: 9, text: 'เติมเงินอาลีเพย์' },
        { value: 10, text: 'โอนเงินไปจีน' },
        { value: 11, text: 'แจ้งไม่ได้รับสินค้า' },
        { value: 12, text: 'แจ้งร้านค้าคืนเงิน' },
        { value: 13, text: 'ค่าจัดส่งในไทย' },
        { value: 14, text: 'แลกเงินหยวนเป็นเงินบาท' },
        { value: 15, text: 'ส่งของไปจีน' },
        { value: 16, text: 'แลกแต้ม' },
        // { value: 17, text: 'อื่นๆ' },
      ],
      showOver: false,
      // Table Taobao
      fieldsTaobao: [
        {
          key: 'number', label: 'หมายเลข', thStyle: { width: '5%' }, thClass: 'text-center',
        },
        {
          key: 'customer', label: 'ลูกค้า', sortable: false, thStyle: { width: '10%' }, thClass: 'text-center',
        },
        {
          key: 'transactionDate', label: 'วันที่ทำรายการ', sortable: false, thStyle: { width: '10%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'keyword', label: 'วลีช่วยจำ (ผู้ดูแล)', sortable: false, thStyle: { width: '20%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'type', label: 'ประเภท', sortable: false, thStyle: { width: '20%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'yuanPaid', label: 'ยอดเงินหยวนที่ต้องชำระ', sortable: false, thStyle: { width: '10%' }, thClass: 'text-center',
        },
        {
          key: 'moneySystem', label: 'ใช้เงินในระบบ', sortable: false, thStyle: { width: '10%' }, thClass: 'text-center',
        },
        {
          key: 'yuan', label: 'ใช้ยอดเงินหยวน', sortable: false, thStyle: { width: '10%' }, thClass: 'text-center',
        },
        {
          key: 'action', label: 'จัดการ', sortable: false, thStyle: { width: '5%' }, thClass: 'text-center', tdClass: 'text-center',
        },
      ],
      itemsTaobao: [],

      // Table Cashback
      fieldsCashback: [
        {
          key: 'number', label: 'หมายเลข', thStyle: { width: '10%' }, thClass: 'text-center',
        },
        {
          key: 'customer', label: 'ลูกค้า', sortable: false, thStyle: { width: '10%' }, thClass: 'text-center',
        },
        {
          key: 'transactionDate', label: 'วันที่ทำรายการ', sortable: false, thStyle: { width: '10%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'keyword', label: 'วลีช่วยจำ (ผู้ดูแล)', sortable: false, thStyle: { width: '45%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'cashback', label: 'รายการคืนเงิน', sortable: false, thStyle: { width: '10%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'yuan', label: 'ใช้ยอดเงินหยวน', sortable: false, thStyle: { width: '10%' }, thClass: 'text-center',
        },
        {
          key: 'action', label: 'จัดการ', sortable: false, thStyle: { width: '5%' }, thClass: 'text-center', tdClass: 'text-center',
        },
      ],
      itemsCashback: [],

      // Table Yuan

      itemsYuan: [],
      items: [],

      pic: null,

      total: 0,
      notpaid: 0,
      waitingforcheck: 0,
      waitingforpayment: 0,
      paid: 0,
      OrderSuccess: 0,
      completetrack: 0,
      completewarehouse: 0,
      cancel: 0,
      status: 0,
      search_val: '',
    }
  },
  computed: {
    fieldsYuan() {
      return [
        {
          key: 'itemnumber_text', label: this.$t('number'), thStyle: { width: '10%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'created_at', label: this.$t('transactionDate'), sortable: false, thStyle: { width: '10%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'order_text', label: this.$t('orderList'), sortable: false, thStyle: { width: '10%' }, thClass: 'text-center',
        },
        {
          key: 'detail', label: this.$t('details'), sortable: false, thStyle: { width: '25%' }, thClass: 'text-center', tdClass: 'text-left',
        },
        {
          key: 'fileimg', label: this.$t('attachedFile'), sortable: false, thStyle: { width: '20%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'refund', label: this.$t('refundList'), sortable: false, thStyle: { width: '10%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'amount', label: this.$t('totalRefund'), sortable: false, thStyle: { width: '10%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'action', label: this.$t('manage'), sortable: false, thStyle: { width: '5%' }, thClass: 'text-center', tdClass: 'text-center',
        },
      ]
    },
  },
  mounted() {
    // this.GetNotify()
    // this.GetOrderAll(0)
    this.getData(this.status, this.search_val)
    // this.getCuslist()
  },
  methods: {
    time(data) {
      return moment(data).format('DD/MM/YYYY HH:mm:ss')
    },
    submit() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          const index = this.typeOp.findIndex(x => x.value === this.type)
          const params = {
            _id: this.id,
            username_cus: this.username_cus,
            type_text: this.typeOp[index].text,
            type: this.type,
            detail: this.detail,
            amount: this.amount,
            prefix: this.prefix,
            prefixnumber: this.prefixnumber,
          }
          this.$http.post('transactions/storeAndupdate', params)
            .then(() => {
              this.getData(this.isActive)
              this.id = null
              this.username_cus = null
              this.type = null
              this.detail = null
              this.amount = 0
              this.prefix = null
              this.prefixnumber = null
              this.Success()
            })
            .catch(error => {
              this.SwalError(error.response.data.message)
            })
          this.$bvModal.hide('modal-add-new')
        }
      })
    },
    GetImg(pathfile, filename) {
        return `${this.$http.defaults.baseURL}/viewimg?pathfile=${pathfile}/${filename}`
    },
    showAddNew() {
      this.id = null
      this.username_cus = null
      this.type = null
      this.detail = null
      this.amount = null
      this.prefix = null
      this.prefixnumber = null
      this.$bvModal.show('modal-add-new')
    },
    showEdit(data) {
      // eslint-disable-next-line no-underscore-dangle
      this.id = data._id
      this.username_cus = data.username_cus
      this.type = data.type
      this.detail = data.detail
      this.amount = data.amount
      this.prefix = data.prefix
      this.prefixnumber = data.prefixnumber
      this.$bvModal.show('modal-add-new')
    },
    // eslint-disable-next-line camelcase
    async getData(type, search_val) {
      this.showOver = true
      const params = {
        page: this.currentPage,
        perPage: this.perPage,
        type,
        search_val,
      }
      this.$http
        .get('/LostPackage/getDatacus', { params })
        .then(response => {
          response.data.data.map(ele => { ele.fileimg = [] })
          this.items = response.data.data
          // console.log(this.items)
          this.totalRows = response.data.total
          this.showOver = false
          this.ShowIMG()
        })
        .catch(error => {
          console.log(error)
          this.showOver = false
        })
    },
    async ShowIMG() {
      const Id = this.items.map(ele => ele._id)
      const params = {
        id: Id,
      }
      this.$http.get('/LostPackage/GetDataimg', { params })
        .then(response => {
          // console.log(response)
          response.data.forEach((data, index) => {
            this.items[index].fileimg = data.fileimg
          })
        })
        .catch(err => console.log(err))
    },
    async getCuslist() {
      this.$http
        .get('/users/cuslist')
        .then(response => {
          this.cuslist = response.data
          console.log(this.cuslist)
        })
        .catch(error => console.log(error))
    },
    EditType(data) {
      // eslint-disable-next-line no-underscore-dangle
      this.$router.push({ name: 'lost-package-edit', params: { id: data._id } })
    },
    // Commas(num) {
    //   return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    // },
    // time(data) {
    //   return moment(data).format('YYYY-MM-DD HH:mm:ss')
    // },
    GetNotify() {
      this.$http
        .get('/order/Notify')
        .then(response => {
          // console.log(response.data)
          this.total = response.data.total
          this.waitingforcheck = response.data.waitingforcheck
          this.waitingforpayment = response.data.waitingforpayment
          this.paid = response.data.paid
          this.OrderSuccess = response.data.OrderSuccess
          this.completetrack = response.data.completetrack
          this.completewarehouse = response.data.completewarehouse
          this.cancel = response.data.cancel
        })
        .catch(error => console.log(error))
    },
    GetOrderAll(type) {
      const params = {
        page: this.currentPage,
        perPage: this.perPage,
        type,
      }
      this.$http
        .get('/order/orderAll', { params })
        .then(response => {
          // console.log(response.data)
          this.items = response.data.data
          // this.totalRows = this.items.length
          this.totalRows = response.data.total
        })
        .catch(error => console.log(error))
    },
    resize(event) {
      // eslint-disable-next-line no-param-reassign
      event.target.style.height = 'auto'
      // eslint-disable-next-line no-param-reassign
      event.target.style.height = `${event.target.scrollHeight}px`
    },
    updateKeyword(id, note) {
      // console.log('Item ID:', id)
      // console.log('note:', note)
      const params = {
        id,
        note,
      }
      // console.log(params)
      this.$http
        .post('/order/updateNote', params)
        // eslint-disable-next-line no-unused-vars
        .then(response => {
          // console.log(response.data)
        })
        .catch(error => console.log(error))
    },
    showModal(data) {
      this.pic = data
      this.$refs['my-modal'].show()
    },
    downloadImg() {
      const imageURL = '@/assets/images/anan-img/example/1.jpg'
      const link = document.createElement('a')
      link.href = imageURL
      link.download = 'image.jpg'
      link.click()
    },
    Success(mes) {
      this.$swal({
        icon: 'success',
        title: '<h3 style="color: #141414">ทำรายการสำเร็จ</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },
    showPicture(picture) {
      this.showLightbox = true
      const img = { path: this.GetImg('lostpackage_file', picture) }
      this.images.push(img)
      // this.$swal({
      //   imageUrl: picture,
      //   imageWidth: 400,
      //   imageHeight: 400,
      //   imageAlt: 'Custom image',
      //   confirmButtonText: 'ปิด',
      // })
    },
    closeBox() {
      this.showLightbox = false
      this.images = []
    },
    SwalError(mes) {
      this.$swal({
        icon: 'error',
        title: '<h3 style="color: #141414">ขออภัยค่ะ !!</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
    },
  },
}
</script>
  <style lang="scss">
  @import "@core/scss/vue/libs/vue-select.scss";
  </style>
